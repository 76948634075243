// src/components/NewsletterHeader.js

import React from 'react';

function NewsletterHeader() {
  return (
    <header className="flex justify-between items-center p-4 bg-gray-100">
      <div className="text-xl font-bold">Logo</div>
      <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">Login</button>
    </header>
  );
}

export default NewsletterHeader;
