import React from 'react';

function CheckMark({ showCheckMark }) {
  return (
    <div className="flex items-center justify-center h-[19px] w-[19px] border border-gray-300 rounded-sm">
      {showCheckMark && (
        <div className="inline-block transform rotate-45 h-[85%] w-[60%] border-b-[3px] border-r-[4px] border-blue-500"></div>
      )}
    </div>
  );
}

export default CheckMark;
