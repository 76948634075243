// src/components/NewsletterSearchBar.js

import React from "react";

function NewsletterSearchBar({
  searchQuery,
  handleSeachQueryChange,
  handleSearch,
}) {
  console.log({ searchQuery });
  
  return (
    <div className="flex justify-center py-8">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => handleSeachQueryChange(e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && handleSearch()}
        placeholder="Search products..."
        className="w-1/2 p-2 text-base border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
  );
}

export default NewsletterSearchBar;
