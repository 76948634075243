// src/components/NewsletterPagination.jsx
import React from 'react';

function NewsletterPagination() {
  return (
    <div className="flex justify-center my-5 space-x-3">
      <button className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200">
        &lt; Previous
      </button>
      <button className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200">1</button>
      <button className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200">2</button>
      <button className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200">3</button>
      <button className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200">
        Next &gt;
      </button>
    </div>
  );
}

export default NewsletterPagination;
