import React from "react";
import NewsletterLogin from "./NewsletterLogin";
import NewsletterRegister from "./NewsletterRegister";
import NewsletterFeed from "./NewsletterFeed";
import NewsletterHeader from "./NewsletterHeader";
import NewsletterFooter from "./NewsletterFooter";
import { Route, Routes } from "react-router-dom";

const NewsletterRoot = () => {
  return (
    <>
      <NewsletterHeader />
      <Routes>
        <Route path="/*" element={<NewsletterFeed />} />
        <Route path="/login" element={<NewsletterLogin />} />
        <Route path="/register" element={<NewsletterRegister />} />
      </Routes>
      <NewsletterFooter />
    </>
  );
};

export default NewsletterRoot;
