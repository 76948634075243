// src/components/NewsletterFilterSidebar.js

import React, { useState } from "react";

function NewsletterFilterSidebar({ filterOptions, onFilterChange }) {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const handleBrandChange = (brand) => {
    const updatedBrands = selectedBrands.includes(brand)
      ? selectedBrands.filter((b) => b !== brand)
      : [...selectedBrands, brand];
    setSelectedBrands(updatedBrands);
    onFilterChange(updatedBrands, selectedDateRange, selectedIndustries);
  };

  const handleDateRangeChange = (dateRange) => {
    const updatedDateRanges = selectedDateRange.includes(dateRange)
      ? selectedDateRange.filter((d) => d !== dateRange)
      : [...selectedDateRange, dateRange];
    setSelectedDateRange(updatedDateRanges);
    onFilterChange(selectedBrands, updatedDateRanges, selectedIndustries);
  };

  const handleIndustryChange = (industry) => {
    const updatedIndustries = selectedIndustries.includes(industry)
      ? selectedIndustries.filter((i) => i !== industry)
      : [...selectedIndustries, industry];
    setSelectedIndustries(updatedIndustries);
    onFilterChange(selectedBrands, selectedDateRange, updatedIndustries);
  };

  console.log({ filterOptions });

  return (
    <aside className="w-1/6 p-4 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">Filters</h2>

      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Brand</h3>
        <div className="flex flex-col space-y-2">
          {filterOptions &&
            filterOptions.brand &&
            Object.keys(filterOptions.brand).map((brand_i, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  className="text-blue-500 focus:ring-blue-500"
                  checked={selectedBrands.includes(brand_i)}
                  onChange={() => handleBrandChange(brand_i)}
                />
                <span>
                  {brand_i} ({filterOptions.brand[brand_i] || ""})
                </span>
              </label>
            ))}
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Date</h3>
        <div className="flex flex-col space-y-2">
          {
            // Add checkboxes for the date ranges here
            filterOptions &&
              filterOptions.date &&
              Object.keys(filterOptions.date).map((dateRange, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="text-blue-500 focus:ring-blue-500"
                    checked={selectedDateRange.includes(dateRange)}
                    onChange={() => handleDateRangeChange(dateRange)}
                  />
                  <span>
                    {dateRange} ({filterOptions.date[dateRange] || ""})
                  </span>
                </label>
              ))
          }
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Industry</h3>
        <div className="flex flex-col space-y-2">
          {
            // Add checkboxes for the industries here
            filterOptions &&
              filterOptions.industry &&
              Object.keys(filterOptions.industry).map((industry, index) => (
                <label key={index} className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    className="text-blue-500 focus:ring-blue-500"
                    checked={selectedIndustries.includes(industry)}
                    onChange={() => handleIndustryChange(industry)}
                  />
                  <span>
                    {industry} ({filterOptions.industry[industry] || ""})
                  </span>
                </label>
              ))
          }
        </div>
      </div>
    </aside>
  );
}

export default NewsletterFilterSidebar;
