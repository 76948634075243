// src/components/NewsletterLoading.jsx

import React from 'react';

const NewsletterLoading = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="flex items-center space-x-2">
        <div className="w-16 h-16 border-4 border-t-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <span className="text-lg text-gray-700">Loading...</span>
      </div>
    </div>
  );
};

export default NewsletterLoading;
