// src/components/NewsletterCardGrid.jsx
import { useNavigate } from "react-router-dom";

const NewsletterCardGrid = ({
  newsletters,
  handleSelectedNewsletter,
  selectedNewsletter,
}) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
      {newsletters.map((newsletter, index) => (
        <div key={index} className="relative bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="absolute top-2 right-2">
            <input
              type="checkbox"
              className="w-6 h-6" // Increase the checkbox size
              checked={selectedNewsletter && selectedNewsletter.find((ele) => ele === newsletter.uid)}
              onClick={() => handleSelectedNewsletter(newsletter.uid)}
            />
          </div>
          <div onClick={() => navigate(`${newsletter.uid}`)}>
            <img
              src={newsletter.image}
              alt="Preview Not available. Click for details"
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-lg font-semibold mb-2">{newsletter.title}</h3>
              <p className="text-gray-500 text-sm mb-2">{newsletter.date}</p>
              { /* <p className="text-gray-700">{newsletter.summary}</p> */ }
              <p className="text-gray-700">{newsletter.uid}</p> 
              <p className="text-gray-700">{newsletter.from}</p> 
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsletterCardGrid;
