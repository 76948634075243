import React from 'react';
import styles from '../styles/NewsletterCard.module.css';

function NewsletterCard({ item}) {
  return (
    <div className="{styles.Newslettercard}">
      <h3>{item.title}</h3>
      <p>{item.content}</p>
      <img className="w-full" src={item.img}/>
    </div>
  );
}

export default NewsletterCard;

