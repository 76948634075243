// src/components/NewsletterCardGrid.jsx
import { useNavigate } from "react-router-dom";

const NewsletterCardGrid = ({ newsletters }) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
      {newsletters.map((newsletter, index) => (
        <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden" onClick={()=>navigate(`${newsletter.uid}`)}>
          <img src={newsletter.image} alt={newsletter.title} className="w-full h-48 object-cover" />
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-2">{newsletter.title}</h3>
            <p className="text-gray-500 text-sm mb-2">{newsletter.date}</p>
            <p className="text-gray-700">{newsletter.summary}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NewsletterCardGrid;
