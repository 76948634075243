import React from "react";

function NewsletterPagination({ currentPage, totalPages, onPageChange }) {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    return Array.from({ length: totalPages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() => handlePageClick(index + 1)}
        className={`px-4 py-2 ${
          index + 1 === currentPage
            ? "bg-blue-500 text-white"
            : "bg-gray-100 text-black"
        } border border-gray-300 rounded hover:bg-gray-200`}
      >
        {index + 1}
      </button>
    ));
  };

  return (
    <div className="flex justify-center my-5 space-x-3">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
      >
        &lt; Previous
      </button>
      {renderPageNumbers()}
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="px-4 py-2 bg-gray-100 border border-gray-300 rounded hover:bg-gray-200 disabled:opacity-50"
      >
        Next &gt;
      </button>
    </div>
  );
}

export default NewsletterPagination;
