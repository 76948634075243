// src/components/NewsletterHeader.js

import React from "react";
import { Link } from "react-router-dom";

function NewsletterHeader() {
  return (
    <header className="flex justify-between items-center p-4 bg-gray-100">
      <Link to={"/newsletters"}>
        <div className="text-xl font-bold">Logo</div>
      </Link>
      <Link to={"/newsletters/login"}>
        <button className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          Login
        </button>
      </Link>
    </header>
  );
}

export default NewsletterHeader;
