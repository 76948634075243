import React, { useEffect, useState } from "react";
import { trackClickEvent } from "../analytics";

import InputField from "./InputField";
import BlueButton from "./BlueButton";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import Toast from "./Toast";
import NewsletterFormLayout from "./NewsletterFormLayout";

function NewsletterLogin() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [success, setSuccess] = useState("");
  const { me } = { me: "" };

  const handleLogin = (e) => {
    e.preventDefault();
    trackClickEvent(e);
    setLoginError("");
    setIsLoggingIn(true);
  };

  useEffect(() => {
    if (me && me.username) {
      navigate("/explore", { replace: true });
    }
  }, [me, navigate]);

  return (
    // <div className="form-container flex items-center w-6/12 m-auto h-full justify-center flex-col">
      <div className="relative">
        {success && <Toast message={success} type="success" />}
        <NewsletterFormLayout welcomeText="Test">
          <form className="w-full flex justify-center flex-col items-center">
            <h4 className="font-bold text-xl">Login</h4>
            {loginError && (
              <div className="w-full text-center text-sm bg-red-100 px-4 py-2 rounded-sm text-red-900">
                {loginError}
              </div>
            )}
            <InputField
              handleChange={(e) => {
                trackClickEvent(e);
                setUserInfo({ ...userInfo, email: e.target.value });
              }}
              value={userInfo?.email}
              type="text"
              classNames="w-full"
              label="Email"
              placeholder="Enter your email"
            />

            <InputField
              handleChange={(e) => {
                trackClickEvent(e);
                setUserInfo({ ...userInfo, password: e.target.value });
              }}
              value={userInfo?.password}
              type="password"
              classNames="w-full"
              label="Password"
              placeholder="Enter your password"
            />

            {/* <TextArea label="Message" placeholder="Your full names" classNames="w-full" /> */}

            <a
              className="w-full primary-color text-right text-xs font-medium cursor-pointer"
              href="http://127.0.0.1:8000/displaycode/reset_password/"
            >
              Forgot password?
            </a>
            <div className="w-full" onClick={handleLogin}>
              <BlueButton
                disabled={!userInfo.email || !userInfo.password}
                classNames="w-full py-3 flex justify-center items-center"
              >
                {isLoggingIn ? "Logging in..." : "Login"}
              </BlueButton>
            </div>

            <p className="w-full flex items-center justify-center gap-3 my-4 text-right text-xs font-medium">
              Don't have an account?{" "}
              <span
                className="primary-color cursor-pointer text-blue-800"
                onClick={(e) => {
                  trackClickEvent(e);
                  navigate("/newsletters/register");
                }}
              >
                Sign up here
              </span>
            </p>
          </form>
        </NewsletterFormLayout>
      </div>
    // </div>
  );
}

export default NewsletterLogin;
