import React, { useState } from "react";
// import "./NewsletterFormLayout.css";
// import { UilPlus, UilSignOutAlt } from "@iconscout/react-unicons";
import GreetingsIcon from "../assets/icons/greetings.svg";
import BlueButton from "./BlueButton";
import useWindowDimensions from "../utils/useWindowDimensions";
import Cookies from "universal-cookie";
import bannerImage from "../assets/images/forms-banner.png";

function NewsletterFormLayout({ children, welcomeText, activeTab }) {
  const { width } = useWindowDimensions();
  const [showSideBar, setShowSideBar] = useState(false);

  const toggleShowSideBar = () => {
    setShowSideBar(!showSideBar);
  };

  const cookies = new Cookies();
  const tokenCookie = cookies.get("token");

  return (
    <div className="forms-layout-container relative">
      <div className="relative font-poppins">
        <div className="flex h-[100vh] top-0 relative">
          <div
            className={`forms-part w-6/12 bg-white ${
              width > 834 && "pt-4 px-3"
            }`}
          >
            <div className="form-container flex items-center w-6/12 m-auto h-full justify-center flex-col">
              {/* Form Goes here */}
              {children}
            </div>
          </div>
          <div
            className="forms-banner flex flex-col items-center justify-between w-6/12 bg-gradient-to-b from-transparent to-[rgba(0,65,202,0.81)] bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${bannerImage})`,
            }}
          >
            <div className="right flex items-center w-10/12 my-3 justify-center gap-5 m-auto">
              {/* <BlueButton preIcon={<UilPlus size="15" />} redirectTo={"/login"}>
                Submit  
              </BlueButton> */}
            </div>
            <div className="greetings flex items-center justify-around bg-[rgba(255,255,255,0.56)] rounded-full py-3 px-5 font-medium gap-3">
              <img src={GreetingsIcon} alt="Greeting hand icon" />
              <span className="text-sm font-bold">{welcomeText}</span>
            </div>
            <div className="slider h-[75px] flex"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsletterFormLayout;
