// src/components/NewsletterFooter.js
import React from 'react';

function NewsletterFooter() {
  return (
    <footer className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 py-10 px-5">
      <div className="column">
        <h4 className="font-bold text-lg">About</h4>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">About Us</a></div>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Our Mission</a></div>
      </div>
      <div className="column">
        <h4 className="font-bold text-lg">Pro</h4>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Features</a></div>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Pricing</a></div>
      </div>
      <div className="column">
        <h4 className="font-bold text-lg">Contact Us</h4>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Email</a></div>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Phone</a></div>
      </div>
      <div className="column">
        <h4 className="font-bold text-lg">Privacy Policy</h4>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Terms of Service</a></div>
        <div><a href="#" className="text-gray-600 hover:text-blue-500">Cookie Policy</a></div>
      </div>
    </footer>
  );
}

export default NewsletterFooter;
