// src/pages/NewsletterDetailPage.jsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const NewsletterDetailPage = ({ allarticles }) => {
  const { uid } = useParams();
  const [article, setArticle] = useState(null);
  useEffect(() => {
    setArticle(allarticles.find((article) => {
        console.log("{article}");
        
        return String(article.uid).localeCompare(uid) === 0}));
  }, [allarticles, uid]);

  if (!article) {
    return <p>Loading...</p>;
  }
  

  return (
    <div className="flex flex-col lg:flex-row mx-auto max-w-6xl p-8 space-y-8 lg:space-y-0 lg:space-x-8">
      {/* Left Sidebar - Metadata */}
      <aside className="lg:w-1/3 w-full bg-gray-100 p-6 rounded-lg shadow-md h-fit">
        {article ? (
          <>
            <h2 className="text-2xl font-bold mb-4">{article?.title}</h2>
            <p className="text-gray-600 mb-4">
              <strong>Brand:</strong> {article?.brand}
            </p>
            <p className="text-gray-600 mb-4">
              <strong>Date:</strong> {article?.date}
            </p>
            <p className="text-gray-600 mb-4">
              <strong>Summary:</strong> {article?.summary}
            </p>
            <p className="text-gray-600">
              <strong>Author:</strong> {article?.from}
            </p>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </aside>

      {/* Main Content - HTML Content */}
      <div className="lg:w-2/3 w-full bg-white p-6 rounded-lg shadow-md">
        <div
          className="prose max-w-full"
          dangerouslySetInnerHTML={{ __html: article?.html }}
        ></div>
      </div>
    </div>
  );
};

export default NewsletterDetailPage;
