import React, { useEffect, useState } from "react";
import NewsletterFormLayout from "./NewsletterFormLayout";
import InputField from "./InputField";
import BlueButton from "./BlueButton";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import CheckMark from "./CheckMark";

const RECAPTCHA_SITE_KEY = "6LfUfVcpAAAAAGW_RDogjBAm_kHgtbTICdmj47d-";

function NewsletterRegister() {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    password: "",
    cnfpassword: "",
  });

  const { me } = {me: ""};
  useEffect(() => {
    if (me && me.username) {
      navigate("/explore", { replace: true });
    }
  }, [me, navigate]);

  const [captchaValue, setCaptchaValue] = useState(null);
  const [agreed, setAgreed] = useState(false);
  const [isRegisteringIn, setIsRegisteringIn] = useState(false);
  const [registerError, setRegisterError] = useState("");

  const handleRegister = (e) => {
    e.preventDefault();
    setRegisterError("");

    if (!captchaValue) {
      return setRegisterError("Please verify you are not a robot.");
    }

    if (userInfo.cnfpassword !== userInfo.password)
      return setRegisterError("Passwords do not match!");

    if (!agreed)
      return setRegisterError("Please, agree to our Terms and Privacy first!");

    setIsRegisteringIn(true);

    const registrationData = {
      ...userInfo,
      "g-recaptcha-response": captchaValue,
    };

  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
    }
  };

  const onCaptchaChange = (value) => {
    // Update state when captcha is solved
    setCaptchaValue(value);
    console.log(value);
  };

  return (
    <div className="relative">
      <NewsletterFormLayout welcomeText="Hi, Join the community!">
        <form className="w-full flex justify-center flex-col items-center">
          <h4 className="font-bold text-xl">Register</h4>
          {registerError && (
            <div className="w-full text-center text-xs bg-red-100 px-4 py-2 rounded-sm text-red-900">
              {registerError}
            </div>
          )}
          <InputField
            handleChange={(e) => {
              setUserInfo({ ...userInfo, username: e.target.value });
            }}
            value={userInfo?.username}
            type="text"
            classNames="w-full"
            label="Username"
            placeholder="Enter your username"
          />
          <InputField
            handleChange={(e) => {
              setUserInfo({ ...userInfo, email: e.target.value });
            }}
            value={userInfo?.email}
            type="email"
            classNames="w-full"
            label="Email"
            placeholder="Enter your email address"
          />
          <InputField
            handleChange={(e) => {
              setUserInfo({ ...userInfo, password: e.target.value });
            }}
            value={userInfo?.password}
            type="password"
            classNames="w-full"
            label="Password"
            placeholder="Enter your password"
          />

          <InputField
            handleChange={(e) => {
              setUserInfo({ ...userInfo, cnfpassword: e.target.value });
            }}
            value={userInfo?.cnfpassword}
            type="password"
            classNames="w-full"
            label="Confirm Password"
            placeholder="Confirm your password"
          />

          <div className="my-4">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={onCaptchaChange}
              className="flex justify-center"
            />
          </div>

          <div
            onClick={(e) => {
              setAgreed(!agreed);
            }}
            className="w-full pl-3 text-center flex gap-2 items-center my-6 text-xs font-medium cursor-pointer"
          >
            <CheckMark showCheckMark={agreed} />
            <span>
              I agree with{" "}
              <span
                className="primary-color"
                onClick={(e) => {
                  navigate("/terms");
                }}
              >
                Terms
              </span>{" "}
              and{" "}
              <span
                className="primary-color"
                onClick={(e) => {
                  navigate("/privacypolicy");
                }}
              >
                Privacy
              </span>
            </span>
          </div>

          <div className="w-full" onClick={handleRegister}>
            <BlueButton
              disabled={
                !userInfo.username || !userInfo.password || !userInfo.email
              }
              classNames="w-full py-3 flex justify-center items-center"
            >
              {isRegisteringIn ? "Registering in..." : "Sign up"}
            </BlueButton>
          </div>

          <p className="w-full flex items-center justify-center gap-3 my-4 text-right text-xs font-medium">
            Already have an account!?{" "}
            <span
              className="primary-color cursor-pointer text-blue-800"
              onClick={(e) => {
                navigate("/newsletters/login");
              }}
            >
              Login here
            </span>
          </p>
        </form>
      </NewsletterFormLayout>
    </div>
  );
}

export default NewsletterRegister;
