export const sampleNewsletters = [
  {
    title: "Monthly Update - October 2023",
    date: "October 1, 2023",
    summary: "Catch up on the latest updates and insights from our team.",
    image: "https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=51.jpg", // Placeholder image
  },
  {
    title: "Special Report: AI Trends 2024",
    date: "September 15, 2023",
    summary:
      "Explore the emerging AI trends and technologies for the coming year.",
    image: "https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=49.jpg", // Placeholder image
  },
  {
    title: "Your Guide to Data Privacy",
    date: "August 20, 2023",
    summary: "Understand the essentials of data privacy in the digital age.",
    image: "https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=50.jpg", // Placeholder image
  },
  {
    title: "How to Boost Productivity",
    date: "July 10, 2023",
    summary: "Effective tips and tools to enhance your productivity at work.",
    image: "https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=49.jpg", // Placeholder image
  },
  {
    title: "The Future of Remote Work",
    date: "June 5, 2023",
    summary:
      "Insights on the evolving landscape of remote work and flexible schedules.",
    image: "https://dev.interviewblindspots.com/displaycode/newsletterimage/?q=51.jpg", // Placeholder image
  },
];
